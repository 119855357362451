import * as React from "react"
import { generateGradient } from "@marko19907/string-to-color"
import * as AvatarPrimitive from "@radix-ui/react-avatar"

import { cn } from "@/lib/utils/classnames"

const Avatar = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Root>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>
>(({ className, ...props }, ref) => (
	<AvatarPrimitive.Root
		ref={ref}
		className={cn(
			"pointer-events-none relative flex size-9 shrink-0 select-none overflow-hidden rounded-full",
			className,
		)}
		{...props}
	/>
))
Avatar.displayName = AvatarPrimitive.Root.displayName

const AvatarImage = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Image>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
	<AvatarPrimitive.Image
		ref={ref}
		className={cn("aspect-square size-full", className)}
		{...props}
	/>
))
AvatarImage.displayName = AvatarPrimitive.Image.displayName

const AvatarFallback = React.forwardRef<
	React.ElementRef<typeof AvatarPrimitive.Fallback>,
	React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, children, ...props }, ref) => {
	const name = String(children)
	const usernameGradient = React.useMemo(() => {
		return generateGradient(
			name,
			undefined,
			{ saturation: 90, lightness: 30, alpha: 100 },
			{ saturation: 30, lightness: 60, alpha: 100 },
		)
	}, [name])
	const getInitials = (name: string) => {
		if (!name || name.trim().length === 0) return undefined
		const names = name.trim().split(/\s+/).filter(Boolean)

		if (names.length === 1) {
			const firstName = names[0]!
			return firstName.length > 1 ?
					firstName.slice(0, 2).toUpperCase()
				:	firstName[0]!.repeat(2).toUpperCase()
		}

		const [first, last] = [names[0]!, names[names.length - 1]!]
		return `${first[0]}${last[0]}`.toUpperCase()
	}
	const initials = getInitials(name)
	return (
		<AvatarPrimitive.Fallback
			ref={ref}
			className={cn(
				"flex size-full items-center justify-center rounded-full text-lg font-medium text-white",
				className,
			)}
			style={{
				backgroundImage: usernameGradient,
			}}
			{...props}
		>
			{initials ?? ""}
		</AvatarPrimitive.Fallback>
	)
})
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName

export { Avatar, AvatarImage, AvatarFallback }
